import {
  isSupported,
  getMessaging,
  onMessage,
  getToken as getFirebaseToken,
} from "firebase/messaging"

import config from "../../config"
import { getFirebaseApp } from "../firebase"
import { logError } from "../logging"
import { getActiveServiceWorkerRegistration } from "../service-worker-registration.web"
import { PermissionStatus, Notifications, PermissionError } from "./base"

export type { PermissionStatus }

export { PermissionError }

class NotificationsWeb extends Notifications {
  constructor() {
    super()
    this.initialize()
  }

  private async initialize() {
    const app = getFirebaseApp()
    const supported = await this.isSupported()
    if (app && supported) {
      try {
        // Show foreground messages using the same mechanism as background
        // messages (by default foreground messages are not shown)
        const registration = await getActiveServiceWorkerRegistration()
        if (registration !== null) {
          const messaging = getMessaging()
          onMessage(messaging, (event) => {
            const { notification, data } = event
            if (notification) {
              const { title = config.APP_NAME, body, image } = notification
              registration.showNotification(title, { body, icon: image, data })
            }
          })
        }
      } catch (error) {
        logError(error)
      }
    }
  }

  isSupported = (): Promise<boolean> =>
    config.NOTIFICATIONS_SUPPORTED ? isSupported() : Promise.resolve(false)

  getPermissionStatus = async (): Promise<PermissionStatus> => {
    const [supported, registration] = await Promise.all([
      this.isSupported(),
      getActiveServiceWorkerRegistration(),
    ])
    return !supported || !registration || typeof Notification === "undefined"
      ? "unavailable"
      : Notification.permission === "granted"
        ? "granted"
        : Notification.permission === "denied"
          ? "blocked"
          : "denied"
  }

  getToken = async ({ requestPermission }: { requestPermission: boolean }) => {
    // Return null if messaging is not supported
    if (!(await this.isSupported())) {
      throw new PermissionError("unavailable")
    }

    // Return null if notifications are unavialable, a previous permission
    // request was blocked, or if don't have permission yet and we don't want to
    // ask for it.
    const permissionStatus = await this.getPermissionStatus()
    if (
      permissionStatus === "unavailable" ||
      permissionStatus === "blocked" ||
      (!requestPermission && permissionStatus === "denied")
    ) {
      throw new PermissionError(permissionStatus)
    }

    // A service worker is required for web push notifications
    const registration = await getActiveServiceWorkerRegistration()
    if (registration === null) {
      throw new Error("[Notifications] No service worker")
    }

    // Get the client's firebase messaging token. This will automatically ask
    // for permission if necessary.
    const messaging = getMessaging()
    return await getFirebaseToken(messaging, {
      vapidKey: config.FIREBASE_MESSAGING_VAPID_PUBLIC_KEY,
      serviceWorkerRegistration: registration,
    }).catch(async (cause) => {
      throw new Error("[Notifications] Couldn't get token from Firebase", {
        cause,
      })
    })
  }
}

export default new NotificationsWeb()
