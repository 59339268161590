import { Platform } from "react-native"

import { Event } from "@treefort/lib/authenticator"
import { SettingsFacade } from "@treefort/lib/settings-facade"

import config from "../config"
import { progressStore } from "../watermelon/stores/progress"
import api from "./api"
import authenticator from "./authenticator"
import { isAxiosNetworkError, logError } from "./logging"
import { getNetworkState, isOfflineState } from "./network-state"
import { Store } from "./store"

export const settingsStore = new Store({
  // Key is a bit funky for reasons lost to time.
  key: Platform.OS === "web" ? "primary" : "store.12",
})

// Run in local-only mode if we don't have an authenticated user or if the
// internet is unreachable
const localOnly = async () => {
  if (authenticator.getUser() === null) {
    return true
  } else if (isOfflineState(await getNetworkState({ staleTime: 10000 }))) {
    return true
  } else {
    return false
  }
}

export default new SettingsFacade({
  store: settingsStore,
  api,
  localOnly,
  onRemoteError: (cause) => {
    // Ignore network errors which are not actionable and occur all the time in
    // the normal course of things.
    if (!isAxiosNetworkError(cause)) {
      logError(new Error("[Settings] Remote store error", { cause }))
    }
  },
  progressStore,
  clientAppVersion: config.APP_VERSION,
})

// Clear out all of a user's local settings when they sign out
authenticator.on(Event.User, async (user) => {
  const settingsUserId = await settingsStore.get<string>("settingsUserId")
  const userId = user?.id ?? null
  if (settingsUserId && !userId) {
    await settingsStore
      .clear()
      .catch((cause) =>
        logError(new Error("[Settings] Failed to clear store", { cause })),
      )
  } else if (settingsUserId !== userId) {
    await settingsStore
      .set("settingsUserId", userId)
      .catch((cause) =>
        logError(new Error("[Settings] Failed save current user", { cause })),
      )
  }
})
