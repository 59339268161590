import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { AppLink, AppMenuLinkLocation } from "@treefort/api-spec"

import config from "../config"
import { consentSettingsEnabled } from "../lib/consent"
import { isLibraryTab } from "../navigation/routes"
import useAppManifest from "./use-app-manifest"
import { useGiftUpCheckoutLink } from "./use-giftup-checkout-link"
import { useShowBranding } from "./use-show-branding"

export function useAppMenuLinkGroups(location: AppMenuLinkLocation) {
  const manifest = useAppManifest()
  const { tabs, externalLinks, menuLinks = [] } = manifest
  const giftUpCheckoutLink = useGiftUpCheckoutLink()
  const { t } = useTranslation()
  const showBranding = useShowBranding()

  const linkGroups = [
    [
      ...menuLinks.map((menuLink) => ({
        title: menuLink.title,
        to: { type: "url" as const, url: menuLink.url },
        locations: menuLink.locations,
      })),
      {
        title: t("Shop"),
        to: { type: "url" as const, url: externalLinks.shop },
        locations: ["desktopFooter", "desktopMenu", "mobileMenu"],
      },
      {
        title: t("Gift a subscription"),
        to: { type: "url" as const, url: giftUpCheckoutLink.data },
        locations: ["desktopFooter", "desktopMenu"],
      },
    ],
    [
      {
        title: t("Settings"),
        to: { type: "path" as const, path: "/menu/settings" },
        locations: ["desktopFooter", "desktopMenu", "mobileMenu"],
      },
      {
        title: t("Downloads"),
        to: {
          type: "path" as const,
          path: "/menu/downloads",
        },
        locations:
          config.DOWNLOADS_SUPPORTED && !tabs.some(isLibraryTab)
            ? ["mobileMenu"]
            : [],
      },
      {
        title: t("Contact"),
        to: { type: "path" as const, path: "/menu/contact" },
        locations: ["desktopFooter", "desktopMenu", "mobileMenu"],
      },
      {
        title: t("About"),
        to: { type: "path" as const, path: "/menu/about" },
        locations: ["desktopFooter", "desktopMenu", "mobileMenu"],
      },
    ],
    [
      {
        title: t("Terms of service"),
        to: { type: "url" as const, url: externalLinks.termsOfService },
        locations: ["desktopFooter", "desktopAbout", "mobileAbout"],
      },
      {
        title: t("Privacy policy"),
        to: { type: "url" as const, url: externalLinks.privacyPolicy },
        locations: ["desktopFooter", "desktopAbout", "mobileAbout"],
      },
      {
        title: t("Cookie policy"),
        to: {
          type: "url" as const,
          url: externalLinks.cookiePolicy,
        },
        locations:
          Platform.OS === "web"
            ? ["desktopFooter", "desktopAbout", "mobileAbout"]
            : [],
      },
      {
        title: t("Cookie settings"),
        to: {
          type: "path" as const,
          path: "/menu/settings#consent",
        },
        locations: consentSettingsEnabled(manifest) ? ["desktopFooter"] : [],
      },
    ],
    [
      {
        title: "Powered by Treefort",
        to: { type: "url" as const, url: config.TREEFORT_SITE_URL },
        locations: showBranding ? ["desktopAbout", "mobileAbout"] : [],
      },
    ],
  ]

  return linkGroups
    .map((links) =>
      links.flatMap(({ title, locations, to }) =>
        locations.includes(location) && isCompleteAppLink(to)
          ? { title, to }
          : [],
      ),
    )
    .filter((links) => links.length > 0)
}

function isCompleteAppLink(
  appLink:
    | AppLink
    | { type: "url"; url: string | undefined | null }
    | { type: "path"; path: string | undefined | null },
): appLink is AppLink {
  switch (appLink.type) {
    case "url":
      return Boolean(appLink.url)
    case "path":
      return Boolean(appLink.path)
    default:
      return true
  }
}
