import { init, ReactNativeOptions } from "@sentry/react-native"

import isObject from "@treefort/lib/is-object"

import config from "../config"
import { rudderstackAnalyticsPlugin } from "./analytics/plugins/rudderstack"
import { isAxiosNetworkError } from "./logging"

/**
 * Initialize our logging service (Sentry)
 *
 * NOTE: This is in a separate module from "./logging" to avoid the circular
 * dependency that would otherwise result from importing
 * "./analytics/plugins/rudderstack"
 */
export const initializeLogging = (options?: ReactNativeOptions): void => {
  init({
    dsn: config.SENTRY_DSN,
    tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE,
    _experiments: config.SENTRY_PROFILES_SAMPLE_RATE
      ? { profilesSampleRate: config.SENTRY_PROFILES_SAMPLE_RATE }
      : undefined,
    environment: config.ENV,
    release: config.SENTRY_RELEASE,
    dist: config.SENTRY_DIST,
    initialScope: { tags: { tenantId: config.TENANT_ID } },
    beforeSend: (event, hint) => {
      // Expo sets a "code" field on all of the errors that it generates. This
      // logic ensures that any such "code" fields make it through to Sentry.
      const code =
        isObject(hint.originalException) && hint.originalException.code
      if (typeof code === "number" || typeof code === "string") {
        event.tags ||= {}
        event.tags.code = code
      }

      // Log _all_ errors in development
      if (config.ENV === "development") {
        const error = hint.originalException || hint.syntheticException || event
        if (error instanceof Error && error.cause) {
          console.error(error.cause)
        }
        console.error(error)
        if (event.extra) {
          console.error(event.extra)
        }
      }

      // Send everything to RudderStack
      rudderstackAnalyticsPlugin.logError(event)

      // Selectively report errors to Sentry
      if (shouldSendEventToSentry(hint.originalException)) {
        return event
      } else {
        return null
      }
    },
    ...options,
  })
}

/**
 * This utility determines whether an event is sent to Sentry or suppressed.
 *
 * We should be very careful about suppressing events, but in some circumstances
 * suppression is necessary in order to cut down on noise and ensure that real
 * errors get the attention they deserve. If an error meets _all_ of these
 * criteria then we should consider suppressing it from Sentry:
 *
 * 1. The error is understood - we know what causes it and what the effect is.
 * 2. The error is not addressable - there's nothing we can do to fix it and
 *    we've done what we can to mitigate the effects.
 * 3. The error provides us with no valuable information - there's nothing
 *    significant that we can learn from it.
 * 4. The error occurs frequently - enough to make it prominent in our logs.
 */
const shouldSendEventToSentry = (error: unknown): boolean => {
  // Logging can be disabled entirely via the PUBLIC_SENTRY_ENABLE_EVENTS env
  // variable (useful for development)
  if (!config.SENTRY_ENABLE_EVENTS) {
    return false
  }

  // Suppress network errors which are not actionable and occur all the time as
  // mobile users roam the earth.
  if (isAxiosNetworkError(error)) {
    return false
  }

  // Suppress iOS playback errors that are caused by the network connection
  // dropping.
  if (
    error instanceof Error &&
    /AVPlayerItem.*code -(1001|1008|1009).*NSURLErrorDomain/.test(
      error.toString(),
    )
  ) {
    return false
  }

  // Suppress Android playback errors that are caused by the network connection
  // dropping.
  if (
    error instanceof Error &&
    /exoplayer.*HttpDataSourceException.*Unable to connect/.test(
      error.toString(),
    )
  ) {
    return false
  }

  // Suppress Expo E_VIDEO_TAGINCORRECT errors which are plentiful and harmless.
  // See: https://github.com/expo/expo/issues/18402
  if (isObject(error) && error.code === "E_VIDEO_TAGINCORRECT") {
    return false
  }

  // Suppress Expo E_AV_SEEKING "Seeking interrupted" errors which are plentiful
  // and harmless.
  if (isObject(error) && error.code === "E_AV_SEEKING") {
    return false
  }

  return true
}
