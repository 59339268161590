import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import { useAuth } from "@treefort/lib/auth-provider"
import { DisplayableError } from "@treefort/lib/displayable-error"
import Settings, { SettingStoreItem } from "@treefort/lib/settings"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import CallToSignUp, {
  CallToSignUpState,
} from "../../components/call-to-sign-up"
import {
  LibraryEmptyMessage,
  getLibraryEmptyMessageHeight,
} from "../../components/library/empty-message"
import {
  getLibraryListItemHeight,
  getLibraryListItemPaddingVertical,
  LibraryListItem,
} from "../../components/library/list-item"
import {
  getLibraryOfflineMessageHeight,
  LibraryOfflineMessage,
} from "../../components/library/offline-message"
import {
  getLibraryTabsHeight,
  LibraryTab,
  LibraryTabs,
} from "../../components/library/tabs"
import { ListViewRef } from "../../components/list-view"
import { useTokens } from "../../components/tokens-provider"
import config from "../../config"
import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import { useEntitlements } from "../../hooks/use-entitlements"
import { useIsFocused } from "../../hooks/use-is-focused"
import { useOfflineState } from "../../hooks/use-offline-state"
import useQueryKey from "../../hooks/use-query-key"
import useSafeAreaInsetAppHeader from "../../hooks/use-safe-area-inset-app-header"
import api from "../../lib/api"
import { stopAudioPlayerIfCurrent } from "../../lib/content-audio"
import DownloadItem from "../../lib/download-item"
import {
  LibraryItem,
  getLibraryItemKey,
  sortLibraryItems,
} from "../../lib/library"
import { logError } from "../../lib/logging"
import {
  getProgressItemFromSettingValue,
  ProgressItemData,
  PlayableProgressItem,
  ReadableProgressItem,
  fetchProgressItemFromSettings,
} from "../../lib/progress-item"
import { queryClient } from "../../lib/query-client"
import settings from "../../lib/settings"
import PageLayout from "../layouts/page"

// This represents an item to display in the virtualized list view. This could
// be a library item or any other bit of UI we need to stack in the library
// page.
type ListViewItem =
  | {
      type: "libraryItem"
      libraryItem: LibraryItem
    }
  | { type: "spacer"; size: number }
  | { type: "offlineMessage" }
  | { type: "emptyMessage" }

function getLibraryItemListViewItem(libraryItem: LibraryItem): ListViewItem {
  return { type: "libraryItem", libraryItem }
}

function getSpacerListViewItem(size: number): ListViewItem {
  return { type: "spacer", size }
}

function getOfflineMessageListViewItem(): ListViewItem {
  return { type: "offlineMessage" }
}

function getEmptyMessageListViewItem(): ListViewItem {
  return { type: "emptyMessage" }
}

const noop = () => {}

export function LibraryScreen(): JSX.Element {
  /**
   * STATE
   */

  const auth = useAuth()
  const profileId = useActiveProfileId()
  const { tokens } = useTokens()
  const focused = useIsFocused()
  const focusedPrev = useRef<boolean>(focused)
  const [offline, refreshOfflineState] = useOfflineState()
  const [tab, setTab] = useState<LibraryTab>(
    offline ? "downloaded" : "inProgress",
  )
  const { t } = useTranslation()
  const appHeaderSafeAreaInset = useSafeAreaInsetAppHeader()
  const authenticated = Boolean(auth.user)
  const allowCallToSignUp = auth.initialized && !authenticated
  const [callToSignUpState, setCallToSignUpState] = useState<CallToSignUpState>(
    allowCallToSignUp ? "loading" : "hidden",
  )
  const [refreshingOfflineState, setRefreshingOfflineState] = useState(false)
  const listView = useRef<ListViewRef<ListViewItem>>(null)
  const willUnmount = useWillUnmount()

  /**
   * QUERIES
   */

  // Fetch the library data. This is essentially a list of all the settings that
  // contain content progress.
  const libraryQueryEnabled = authenticated && !offline && focused
  const libraryQueryKey = useQueryKey(["library", profileId])
  const libraryQuery = useQuery(
    libraryQueryKey,
    async () => {
      const { data: remoteSettings } = await api.get<
        SettingStoreItem<ProgressItemData>[]
      >(
        "/library",
        profileId
          ? { headers: { "X-Treefort-Profile": profileId } }
          : undefined,
      )
      const remoteProgressItems = remoteSettings
        .map((remoteSetting) =>
          getProgressItemFromSettingValue({
            value: remoteSetting.value,
            profileId,
          }),
        )
        .flatMap((setting) => (setting ? [setting] : []))

      const localSettings = await settings.getManyLocal<ProgressItemData>(
        remoteProgressItems.map((progressItem) => progressItem.getKey()),
        { profileId },
      )

      return Promise.all(
        remoteSettings.map(async (remoteSetting, index) => {
          const remoteProgressItem = remoteProgressItems[index]
          const localSetting = localSettings[index]
          const isRemoteMoreRecent =
            localSetting?.timestamp &&
            remoteSetting.timestamp > localSetting.timestamp

          // Keep the local setting up to date if the remote setting is newer.
          // This will ensure things stay consistent when we go offline and
          // don't have access to remote settings.
          if (isRemoteMoreRecent) {
            await remoteProgressItem.saveLocal()
          }

          return isRemoteMoreRecent || !localSetting?.value
            ? {
                progressItem: remoteProgressItem,
                progressUpdatedAt: remoteSetting.timestamp,
              }
            : {
                progressItem: getProgressItemFromSettingValue({
                  value: localSetting.value,
                  profileId,
                }),
                progressUpdatedAt: localSetting.timestamp || 0,
              }
        }),
      )
    },
    { enabled: libraryQueryEnabled },
  )

  // Fetch the user's entitlements
  const entitlementsQuery = useEntitlements({ enabled: libraryQueryEnabled })
  const refetchEntitlementsQuery = entitlementsQuery.refetch

  // We want to keep library data as up-to-date as possible, but we also don't
  // want to slow down access to downloads by refetching the library query. It
  // would be very annoying to have a slow/spotty network prevent you from
  // viewing downloaded content. To stike a middle-ground we allow the library
  // query to be refetched on any tab _except_ the "Downloaded" tab.
  const refetch = libraryQuery.refetch
  const refetchLibraryQuery = useCallback(
    (tab: LibraryTab) => {
      if (libraryQueryEnabled && tab !== "downloaded") {
        refetchEntitlementsQuery()
        refetch()
      }
    },
    [libraryQueryEnabled, refetch, refetchEntitlementsQuery],
  )

  // Extract content and progress data for all downloads out of the local store
  const downloadsQueryEnabled = authenticated && focused
  const downloadsQueryKey = useQueryKey(["downloads", profileId])
  const downloadsQuery = useQuery(
    downloadsQueryKey,
    async () => {
      const downloadItems = await DownloadItem.getAll()
      return Promise.all(
        downloadItems.map(async (downloadItem) => {
          const consumableContent = downloadItem.getConsumableContent()
          const { progressItem, timestamp } =
            await fetchProgressItemFromSettings({
              consumableContent,
              profileId,
              strategy: "local",
            })
          return {
            downloadItem,
            progressItem,
            progressUpdatedAt: timestamp || 0,
          }
        }),
      )
    },
    { enabled: downloadsQueryEnabled },
  )
  const refetchDownloadQuery = downloadsQueryEnabled
    ? downloadsQuery.refetch
    : noop

  /**
   * LIST VIEW ITEMS
   */

  // This merges progress settings nabbed from the remote library endpoint with
  // progress settings that were pulled out of local storage to accompany
  // downloads and user entitlements. This ensures that we show the most
  // up-to-date data that we have as soon as we can. We don't make a user wait
  // to access their downloads while library data is loading, but we also don't
  // show the user outdated progress for downloads when the library loads
  // successfully.
  const allItems = useMemo<LibraryItem[]>(() => {
    const libraryQueryData = offline ? [] : libraryQuery.data || []
    const entitlementsQueryData = offline ? [] : entitlementsQuery.data || []
    const downloadsQueryData = downloadsQuery.data || []

    // Slurp all the data from the library API endpoint, the local downloads
    // store, and the entitlements API endpoint together into a single list for
    // processing
    const allInputData: Array<
      | { type: "withProgressItem"; contentId: number; progressItemKey: string }
      | { type: "withoutProgressItem"; contentId: number }
    > = [
      ...libraryQueryData.map((item) => ({
        type: "withProgressItem" as const,
        contentId: item.progressItem.getData().contentId,
        progressItemKey: item.progressItem.getKey(),
      })),
      ...downloadsQueryData.map((item) => ({
        type: "withProgressItem" as const,
        contentId: item.progressItem.getData().contentId,
        progressItemKey: item.progressItem.getKey(),
      })),
      ...entitlementsQueryData.map((item) => ({
        type: "withoutProgressItem" as const,
        contentId: item.contentId,
      })),
    ].filter((item, index, arr) => {
      switch (item.type) {
        case "withProgressItem":
          return (
            arr.findIndex(
              (arrItem) =>
                arrItem.type === "withProgressItem" &&
                arrItem.progressItemKey === item.progressItemKey,
            ) === index
          )
        case "withoutProgressItem":
          return (
            arr.findIndex((arrItem) => arrItem.contentId === item.contentId) ===
            index
          )
      }
    })

    // Map over the input data to generate library items
    return allInputData.flatMap((dataItem) => {
      const downloadsQueryItem =
        dataItem.type === "withProgressItem"
          ? downloadsQueryData.find(
              (item) => item.progressItem.getKey() === dataItem.progressItemKey,
            )
          : undefined
      const libraryQueryItem =
        dataItem.type === "withProgressItem"
          ? libraryQueryData.find(
              (item) => item.progressItem.getKey() === dataItem.progressItemKey,
            )
          : undefined
      const entitlementsQueryItem = entitlementsQueryData.find(
        (item) => item.contentId === dataItem.contentId,
      )

      // This should never happen, but we might as well be correct and make
      // TypeScript happy.
      if (!downloadsQueryItem && !libraryQueryItem && !entitlementsQueryItem) {
        return []
      }

      // Prefer library query data as it's more likely to be up-to-date
      const progressItem =
        libraryQueryItem?.progressItem || downloadsQueryItem?.progressItem
      const progressUpdatedAt =
        libraryQueryItem?.progressUpdatedAt ||
        downloadsQueryItem?.progressUpdatedAt
      const { contentId } = progressItem
        ? progressItem.getData()
        : // The preceding conditionals guarantee that we'll either have a
          // progress item or an entitlement item
          //
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          entitlementsQueryItem!

      return {
        contentId,
        progressItem,
        progressUpdatedAt,
        downloadItem: downloadsQueryItem?.downloadItem,
        entitled: Boolean(entitlementsQueryItem),
      }
    })
  }, [offline, libraryQuery.data, downloadsQuery.data, entitlementsQuery.data])

  // Filter the items down to whatever should show up in the currently selected
  // tab.
  const filteredItems = useMemo(() => {
    return allItems.filter((item) => {
      switch (tab) {
        case "inProgress": {
          if (item.progressItem instanceof PlayableProgressItem) {
            const progress = item.progressItem.getProgress()
            return (
              progress.finished === false &&
              (progress.index > 0 || progress.position > 0)
            )
          } else if (item.progressItem instanceof ReadableProgressItem) {
            const progress = item.progressItem.getProgress()
            return progress.finished === false && progress.percent > 0
          } else {
            return false
          }
        }
        case "notStarted": {
          if (!item.progressItem) {
            return false
          }
          if (item.progressItem instanceof PlayableProgressItem) {
            const progress = item.progressItem?.getProgress()
            return (
              !progress.finished &&
              progress.index === 0 &&
              progress.position === 0
            )
          } else if (item.progressItem instanceof ReadableProgressItem) {
            const progress = item.progressItem.getProgress()
            return !progress.finished && progress.percent === 0
          } else {
            return false
          }
        }
        case "finished":
          return item.progressItem?.getProgress().finished === true
        case "downloaded":
          return Boolean(item.downloadItem)
        case "purchased":
          return Boolean(item.entitled)
      }
    })
  }, [allItems, tab])

  // Sort items first by most recently played and second by most recently
  // downloaded.
  const filteredAndSortedItems = useMemo(() => {
    return sortLibraryItems(filteredItems)
  }, [filteredItems])

  // Calculate various dimensions (important for virtualized lists)
  const libraryItemHeight = getLibraryListItemHeight(tokens)
  const libraryItemPaddingVertical = getLibraryListItemPaddingVertical(tokens)
  const emptyMessageHeight = getLibraryEmptyMessageHeight(tokens)
  const offlineMessageHeight = useMemo(
    // Memoize since this one's a bit more expensive
    () => getLibraryOfflineMessageHeight(tokens),
    [tokens],
  )
  const libraryTabsHeight = useMemo(
    // Memoize since this one's a bit more expensive
    () => (authenticated ? getLibraryTabsHeight(tokens) : 0),
    [tokens, authenticated],
  )
  const headerHeight = appHeaderSafeAreaInset + libraryTabsHeight

  // Add spacers above/below the library items (if we're rendering any). This
  // may seem a bit complex just for some padding, but its necessary when
  // working with a virtualized layout.
  const listViewItems = useMemo(
    () =>
      allowCallToSignUp && callToSignUpState === "visible"
        ? []
        : libraryQuery.isSuccess &&
            downloadsQuery.isSuccess &&
            filteredAndSortedItems.length === 0 &&
            !offline
          ? [
              getSpacerListViewItem(headerHeight + libraryItemPaddingVertical),
              getEmptyMessageListViewItem(),
            ]
          : [
              getSpacerListViewItem(headerHeight + libraryItemPaddingVertical),
              ...(offline ? [getOfflineMessageListViewItem()] : []),
              ...filteredAndSortedItems.map(getLibraryItemListViewItem),
              getSpacerListViewItem(libraryItemPaddingVertical),
            ],
    [
      allowCallToSignUp,
      callToSignUpState,
      libraryQuery.isSuccess,
      downloadsQuery.isSuccess,
      filteredAndSortedItems,
      offline,
      headerHeight,
      libraryItemPaddingVertical,
    ],
  )

  /**
   * EVENT HANDLERS
   */

  const onChangeTab = useCallback(
    (tab: LibraryTab) => {
      listView.current?.scrollToOffset(0)
      setTab(tab)
      setRefreshingOfflineState(true)
      refreshOfflineState().then(
        () => !willUnmount.current && setRefreshingOfflineState(false),
      )
      refetchDownloadQuery()
      refetchLibraryQuery(tab)
    },
    [
      refreshOfflineState,
      refetchLibraryQuery,
      refetchDownloadQuery,
      willUnmount,
    ],
  )

  // Mark an item as finished and optimistically update the library query data.
  const onPressMarkItemFinished = useCallback(
    async (libraryItem: LibraryItem) => {
      try {
        if (!libraryItem.progressItem) {
          throw new Error(
            `[Library] Missing progress item for content #${libraryItem.contentId}`,
          )
        }

        const libraryItemKey = getLibraryItemKey(libraryItem)
        await stopAudioPlayerIfCurrent(libraryItem.progressItem)
        libraryItem.progressItem.updateProgress({ finished: true })
        libraryItem.progressItem.saveLocalAndRemote()
        queryClient.setQueryData<LibraryItem[]>(
          libraryQueryKey,
          (libraryItems) => {
            if (libraryItems) {
              const index = libraryItems.findIndex(
                (libraryItem) =>
                  getLibraryItemKey(libraryItem) === libraryItemKey,
              )
              if (index > -1) {
                return [
                  ...libraryItems.slice(0, index),
                  {
                    ...libraryItems[index],
                    progressItem: libraryItem.progressItem,
                    progressUpdatedAt: Settings.getUnixTimestampSeconds(),
                  },
                  ...libraryItems.slice(index + 1),
                ]
              }
            }
            return libraryItems || []
          },
        )
      } catch (error) {
        logError(
          new DisplayableError(
            t(
              "An error occurred marking the item as finished. Please try again.",
            ),
            error,
          ),
        )
      }
    },
    [libraryQueryKey, t],
  )

  // Remove an item from the library by deleting its setting, optimistically
  // updating the library query data, and clearing the audio player if the item
  // is loaded into it.
  const onPressRemoveItem = useCallback(
    async (libraryItem: LibraryItem) => {
      try {
        if (!libraryItem.progressItem) {
          throw new Error(
            `[Library] Missing progress item for content #${libraryItem.contentId}`,
          )
        }

        // Optimistically update the library query
        const libraryItemKey = getLibraryItemKey(libraryItem)
        queryClient.setQueryData<LibraryItem[]>(
          libraryQueryKey,
          (libraryItems) =>
            libraryItems?.filter(
              (libraryItem) =>
                getLibraryItemKey(libraryItem) !== libraryItemKey,
            ) || [],
        )

        // Optimistically update the downloads query
        if (libraryItem.downloadItem) {
          const downloadItemKey = libraryItem.downloadItem.getKey()
          queryClient.setQueriesData<LibraryItem[]>(
            downloadsQueryKey,
            (libraryItems) =>
              libraryItems?.filter(
                (libraryItem) =>
                  !libraryItem.downloadItem ||
                  libraryItem.downloadItem.getKey() !== downloadItemKey,
              ) || [],
          )
        }

        // Clear the audio player if the item we're removing is loaded
        await stopAudioPlayerIfCurrent(libraryItem.progressItem)

        // Clear out the store and delete any downloaded content
        await Promise.all([
          libraryItem.progressItem.clearLocalAndRemote(),
          libraryItem.downloadItem?.deleteDownload(),
        ])
      } catch (error) {
        logError(
          new DisplayableError(
            t("An error occurred while removing item. Please try again."),
            error,
          ),
        )
      }
    },
    [libraryQueryKey, downloadsQueryKey, t],
  )

  /**
   * OTHER HOOKS
   */

  const asyncViewProps = useAsyncViewPropsForQueries(
    // The virtualized list gets a bit janky if the list of items changes after
    // you start scrolling, so the options passed here are desigend to ensure
    // that the loading spinner is shown any time the list might change.
    tab === "downloaded" || offline
      ? [downloadsQuery]
      : [downloadsQuery, libraryQuery],
    {
      disableBackgroundRefetch: true,
      forceLoading:
        refreshingOfflineState ||
        (allowCallToSignUp && callToSignUpState === "loading"),
    },
  )

  // Check again to see if we're still offline every time the user comes back to
  // the page.
  useEffect(() => {
    const focusedChange = focused !== focusedPrev.current
    focusedPrev.current = focused
    if (focusedChange) {
      // Refetch everything when the page comes into focus to keep the data
      // fresh
      if (focused) {
        refreshOfflineState()
        refetchDownloadQuery()
        refetchLibraryQuery(tab)
      }
      // Reset the queries when the page loses focus so that it's already in a
      // loading state when the page comes back into focus.
      else {
        queryClient.resetQueries({ queryKey: libraryQueryKey })
        queryClient.resetQueries({ queryKey: downloadsQueryKey })
      }
    }
  }, [
    tab,
    focused,
    refreshOfflineState,
    refetchLibraryQuery,
    refetchDownloadQuery,
    libraryQueryKey,
    downloadsQueryKey,
  ])

  /**
   * RENDER
   */

  return (
    <AsyncViewOfflineProvider
      {...asyncViewProps}
      offlineStateDisabled={config.DOWNLOADS_SUPPORTED && authenticated}
    >
      <PageLayout
        headerDropShadow={!authenticated}
        headerChildren={
          authenticated ? (
            <LibraryTabs currentTab={tab} onChange={onChangeTab} />
          ) : undefined
        }
        headerChildrenHeight={libraryTabsHeight}
        listViewProps={
          // Only render the list view when we're in a success state. This
          // ensures that the scroll is reset every time we switch tabs (and
          // show a loading state) and prevents performance issues related to
          // trying to update many list items in-place.
          asyncViewProps.state === "success" &&
          (!allowCallToSignUp || callToSignUpState === "hidden")
            ? {
                ref: listView,
                items: listViewItems,
                renderItem: (item) =>
                  item.type === "libraryItem" ? (
                    <LibraryListItem
                      isOffline={offline}
                      onPressRemove={
                        tab !== "purchased" ? onPressRemoveItem : undefined
                      }
                      onPressMarkFinished={
                        tab !== "finished" &&
                        !(
                          item.libraryItem.progressItem?.getData().progress
                            ?.finished === true
                        )
                          ? onPressMarkItemFinished
                          : undefined
                      }
                      libraryItem={item.libraryItem}
                    />
                  ) : item.type === "offlineMessage" ? (
                    <LibraryOfflineMessage height={offlineMessageHeight} />
                  ) : item.type === "emptyMessage" ? (
                    <LibraryEmptyMessage
                      tab={tab}
                      height={emptyMessageHeight}
                    />
                  ) : null,
                getItemKey: (item, index) =>
                  `${tab}-${item.type}-${
                    item.type === "libraryItem"
                      ? getLibraryItemKey(item.libraryItem)
                      : item.type === "spacer"
                        ? `${index}-${item.size}`
                        : index
                  }`,
                getItemSize: (item) =>
                  item.type === "libraryItem"
                    ? libraryItemHeight
                    : item.type === "spacer"
                      ? item.size
                      : item.type === "offlineMessage"
                        ? offlineMessageHeight
                        : item.type === "emptyMessage"
                          ? emptyMessageHeight
                          : 0,
              }
            : undefined
        }
      >
        {allowCallToSignUp ? (
          <CallToSignUp
            onStateChange={setCallToSignUpState}
            showLogo={tokens.appHeader.mode !== "desktop"}
            paddingTop={headerHeight}
            paddingHorizontal="pagePaddingHorizontal"
            paddingBottom="large"
          />
        ) : null}
      </PageLayout>
    </AsyncViewOfflineProvider>
  )
}
