/**
 * ConsumableContent is an abstraction over our Content type that represents
 * content that can be passed to one of our "players" (e.g. video player, audio
 * player, ereader).
 */
import {
  AudiobookResponse,
  PodcastResponse,
  PodcastEpisodeResponse,
  VideoResponse,
  EbookResponse,
  WebEmbedResponse,
  ContentResponse,
} from "@treefort/api-spec"
import { getAvailableData, isAvailable } from "@treefort/lib/availability"
import { joinContributorNames } from "@treefort/lib/contributor"
import isPlainObject from "@treefort/lib/is-plain-object"
import {
  getBestMediaSourceForDownloadingAudio,
  getBestMediaSourceForDownloadingVideo,
  getBestMediaSourceForStreamingAudio,
  getBestMediaSourceForStreamingVideo,
} from "@treefort/lib/media"

import config from "../config"
import { Track } from "./audio-player"
import { getMetadataFromContent } from "./content"
import { stringKeyLookup } from "./i18n/string-key-lookup"

// The full episode list is dropped from the item to keep the size down (these
// items are designed to be easily serialized and saved in local storage)
type PodcastWithoutEpisodes = PodcastResponse & {
  details: Omit<PodcastResponse["details"], "episodes">
}

export type PodcastEpisodeConsumableContent = {
  type: "podcastEpisode"
  content: PodcastWithoutEpisodes
  podcastEpisode: PodcastEpisodeResponse
}

export type AudiobookConsumableContent = {
  type: "book"
  content: AudiobookResponse
}

export type VideoConsumableContent = {
  type: "video"
  content: VideoResponse
}

export type EbookConsumableContent = {
  type: "ebook"
  content: EbookResponse
}

export type WebEmbedConsumableContent = {
  type: "webEmbed"
  content: WebEmbedResponse
}

export type PlayableContent =
  | PodcastEpisodeConsumableContent
  | AudiobookConsumableContent
  | VideoConsumableContent

export type ReadableContent = EbookConsumableContent | WebEmbedConsumableContent

export type ConsumableContent = PlayableContent | ReadableContent

export type ConsumableContentForContentResponse<T extends ContentResponse> =
  T extends AudiobookResponse
    ? AudiobookConsumableContent
    : T extends EbookResponse
      ? EbookConsumableContent
      : T extends VideoResponse
        ? VideoConsumableContent
        : T extends PodcastResponse
          ? PodcastEpisodeConsumableContent
          : T extends WebEmbedResponse
            ? WebEmbedConsumableContent
            : null

export type ConsumableContentTrack = Track<{
  consumableContent: PlayableContent
  profileId: string | null
}>

export const getConsumableContentFromPodcastResponse = (
  content: PodcastResponse,
  podcastEpisodeNumber: number,
): PodcastEpisodeConsumableContent => {
  // Find the specific episode we're interested in
  const episode = content.details.episodes.find(
    (episode) => episode.episode === podcastEpisodeNumber,
  )
  if (!episode) {
    throw new Error(
      `Failed to create ConsumableContent: episode "${podcastEpisodeNumber}" not found in podcast "${content.id}"`,
    )
  }

  // Delete the full list of episodes from the podcast
  const detailsWithoutEpisodes = {
    ...content.details,
    episodes: undefined,
  }
  delete detailsWithoutEpisodes.episodes
  const contentWithoutEpisodes = {
    ...content,
    details: detailsWithoutEpisodes,
  } as unknown as PodcastWithoutEpisodes

  return {
    type: "podcastEpisode",
    content: contentWithoutEpisodes,
    podcastEpisode: episode,
  }
}

export const getConsumableContentFromAudiobookResponse = (
  content: AudiobookResponse,
): AudiobookConsumableContent => ({
  type: "book",
  content,
})

export const getConsumableContentFromVideoResponse = (
  content: VideoResponse,
): VideoConsumableContent => ({
  type: "video",
  content,
})

export const getConsumableContentFromEbookResponse = (
  content: EbookResponse,
): EbookConsumableContent => ({
  type: "ebook",
  content,
})

export const getConsumableContentFromWebEmbedResponse = (
  content: WebEmbedResponse,
): WebEmbedConsumableContent => ({
  type: "webEmbed",
  content,
})

export function getConsumableContentFromContentResponse<
  T extends ContentResponse,
>({
  content,
  podcastEpisodeNumber,
}: {
  content: T
  podcastEpisodeNumber?: number
}) {
  switch (content?.type) {
    case "book":
      return getConsumableContentFromAudiobookResponse(
        content,
      ) as ConsumableContentForContentResponse<T>
    case "ebook":
      return getConsumableContentFromEbookResponse(
        content,
      ) as ConsumableContentForContentResponse<T>
    case "webEmbed":
      return getConsumableContentFromWebEmbedResponse(
        content,
      ) as ConsumableContentForContentResponse<T>
    case "video":
      return getConsumableContentFromVideoResponse(
        content,
      ) as ConsumableContentForContentResponse<T>
    case "podcast": {
      if (podcastEpisodeNumber === undefined) {
        throw new Error(
          `Failed to create ConsumableContent: no episode number provided for podcast "${content.id}"`,
        )
      }
      return getConsumableContentFromPodcastResponse(
        content,
        podcastEpisodeNumber,
      ) as ConsumableContentForContentResponse<T>
    }
    default:
      return null as ConsumableContentForContentResponse<T>
  }
}

export const getKeyFromConsumableContent = (item: ConsumableContent): string =>
  item.type === "podcastEpisode"
    ? `${item.type}.${item.content.id}.${item.podcastEpisode.episode}`
    : `${item.type}.${item.content.id}`

export const getTracksFromConsumableContent = ({
  consumableContent,
  profileId,
}: {
  consumableContent?: ConsumableContent
  profileId: string | null
}): ConsumableContentTrack[] => {
  switch (consumableContent?.type) {
    case "podcastEpisode":
      return isAvailable(consumableContent.podcastEpisode.audioMedia)
        ? [
            {
              url: consumableContent.podcastEpisode.audioMedia.data.url,
              title: consumableContent.podcastEpisode.title,
              artwork: getArtworkUrlFromConsumableContent(consumableContent),
              album: consumableContent.content.title,
              extra: { consumableContent, profileId },
            },
          ]
        : []

    case "book":
      return consumableContent.content.details.chapters.flatMap((chapter) => {
        const data = getAvailableData(chapter.audioMedia)
        const mediaSource = data && getBestMediaSourceForStreamingAudio(data)
        return mediaSource
          ? [
              {
                url: mediaSource.url,
                headers: mediaSource.headers,
                query: mediaSource.query,
                cors: mediaSource.cors,
                duration: mediaSource.duration,
                title: chapter.title,
                artist: joinContributorNames(
                  consumableContent.content.contributors,
                  "author",
                ),
                album: consumableContent.content.title,
                artwork: getArtworkUrlFromConsumableContent(consumableContent),
                extra: { consumableContent, profileId },
              },
            ]
          : []
      })

    case "video": {
      const data = getAvailableData(
        consumableContent.content.details.videoMedia,
      )
      const mediaSource = data && getBestMediaSourceForStreamingVideo(data)
      return mediaSource
        ? [
            {
              url: mediaSource.url,
              headers: mediaSource.headers,
              query: mediaSource.query,
              cors: mediaSource.cors,
              duration: mediaSource.duration,
              title: consumableContent.content.title,
              artwork: getArtworkUrlFromConsumableContent(consumableContent),
              album:
                getMetadataFromConsumableContent(consumableContent).subtitle,
              extra: { consumableContent, profileId },
            },
          ]
        : []
    }

    default:
      return []
  }
}

export function getArtworkMediaFromConsumableContent(
  consumableContent: ConsumableContent | undefined,
) {
  switch (consumableContent?.type) {
    case "podcastEpisode":
      return consumableContent.podcastEpisode.artworkMedia

    default:
      return consumableContent?.content.artworkMedia
  }
}

export function getArtworkUrlFromConsumableContent(
  consumableContent: ConsumableContent | undefined,
) {
  const media = getArtworkMediaFromConsumableContent(consumableContent)
  switch (media?.type) {
    case "imageFile":
      return media.original.url

    case "url":
      return media?.url
  }
}

export const isConsumableContentAvailable = (
  consumableContent?: ConsumableContent,
): boolean => {
  switch (consumableContent?.type) {
    case "podcastEpisode":
      return isAvailable(consumableContent.podcastEpisode.audioMedia)

    case "book":
      return consumableContent.content.details.chapters.some((chapter) =>
        isAvailable(chapter.audioMedia),
      )

    case "video":
      return isAvailable(consumableContent.content.details.videoMedia)

    case "ebook":
      return isAvailable(consumableContent.content.details.ebookMedia)

    default:
      return false
  }
}

export const isConsumableContentTrack = (
  track: Track,
): track is ConsumableContentTrack =>
  isPlainObject(track.extra) &&
  "consumableContent" in track.extra &&
  isPlainObject(track.extra.consumableContent) &&
  "profileId" in track.extra

/**
 * Returns normalized metadata for any consumable content. Useful for display in
 * lists.
 */
export function getMetadataFromConsumableContent(
  consumableContent: ConsumableContent,
) {
  switch (consumableContent.type) {
    case "podcastEpisode":
      return {
        title: consumableContent.podcastEpisode.title,
        displayTypeStringKey:
          stringKeyLookup.consumableContentType[consumableContent.type],
        subtitle: consumableContent.content.title,
        artwork: getArtworkMediaFromConsumableContent(consumableContent),
        description: consumableContent.podcastEpisode.description,
      }
    default:
      return getMetadataFromContent(consumableContent.content)
  }
}

export const canDownloadConsumableContent = (
  consumableContent: ConsumableContent,
): boolean => {
  if (!config.DOWNLOADS_SUPPORTED) return false
  switch (consumableContent.type) {
    case "podcastEpisode":
      return isAvailable(consumableContent.podcastEpisode.audioMedia)

    case "book": {
      const { chapters } = consumableContent.content.details
      return (
        chapters.length > 0 &&
        chapters.every(
          (chapter) =>
            isAvailable(chapter.audioMedia) &&
            getBestMediaSourceForDownloadingAudio(chapter.audioMedia.data) !==
              undefined,
        )
      )
    }

    case "ebook":
      return isAvailable(consumableContent.content.details.ebookMedia)

    case "video": {
      const { videoMedia } = consumableContent.content.details
      return isAvailable(videoMedia)
        ? getBestMediaSourceForDownloadingVideo(videoMedia.data) !== undefined
        : false
    }

    default:
      return false
  }
}
