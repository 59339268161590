import throttle from "lodash/throttle"

import { EventEmitter } from "@treefort/lib/event-emitter"
import { uniqueId } from "@treefort/lib/unique-id"

import { syncManager } from "../watermelon/sync"
import { PROGRESS_UPDATE_INTERVAL } from "./av/constants"
import MediaSession from "./av/media-session"
import { Track, Event, EventMap, Progress, PlaybackState } from "./av/types"

export type { Track, Progress }
export { PlaybackState, Event }

/**
 * This class is used to track state for a video player. Tracking this state
 * outside of React is important for coordinating video playback between
 * different video players and the audio player. Unlike the AudioPlayer class
 * which controls audio playback as well as managing state, the VideoPlayer
 * class only manages state. This is due to the fact that actual video player
 * element and controls are rendered by the OS and not by us (see the
 * VideoPlayerInline component).
 */
export class VideoPlayer extends EventEmitter<EventMap> {
  static PROGRESS_UPDATE_INTERVAL = PROGRESS_UPDATE_INTERVAL

  private track: Track | null
  private playbackState: PlaybackState
  private initialPosition: number
  private progress: Progress
  private playbackRate: number
  private suspended: boolean
  private mediaSession: MediaSession

  constructor(options?: {
    playbackState?: PlaybackState
    suspended?: boolean
  }) {
    super()

    this.track = null
    this.playbackState = options?.playbackState || PlaybackState.Idle
    this.initialPosition = 0
    this.progress = { position: 0, duration: 0 }
    this.playbackRate = 1
    this.suspended = options?.suspended || false
    this.mediaSession = new MediaSession(uniqueId("videoPlayer"), {
      skipBackwardInterval: 10000,
      skipForwardInterval: 30000,
      onPlay: () => this.emitter.emit(Event.PlayRequest),
      onPause: () => {
        syncManager.requestSync({ syncType: "immediate" })
        this.emitter.emit(Event.PauseRequest)
      },
      onSeek: (position) => this.emitter.emit(Event.SeekRequest, position),
      onSkip: (interval) => this.emitter.emit(Event.SkipRequest, interval),
    })

    // Activate the media session when the playback state changes
    this.on(Event.PlaybackState, (playbackState) => {
      const track = this.getTrack()
      if (track && !this.suspended) {
        this.mediaSession.activate({
          track,
          state: {
            ...this.progress,
            playbackState,
            playbackRate: this.playbackRate,
          },
        })
      }
    })

    // Keep the media session's playback rate up to date
    this.on(Event.PlaybackRate, (playbackRate) => {
      const track = this.getTrack()
      if (track && !this.suspended) {
        this.mediaSession.update({ state: { playbackRate } })
      }
    })

    // Update the media session when switching tracks
    this.on(Event.Track, (track) => {
      if (track === null && !this.suspended) {
        this.mediaSession.deactivate()
      } else if (track && !this.suspended) {
        this.mediaSession.update({
          track,
          state: {
            ...this.progress,
            playbackState: this.playbackState,
            playbackRate: this.playbackRate,
          },
        })
      }
    })

    // Update the media session when we detect a seek action. Without this the
    // media session's current position would get out of sync with the actual
    // playback position. Because expo-av doesn't emit any sort of seek event,
    // we interpret any jump in position that's greater than twice what we'd
    // expect (e.g. 2s when playing at 1x speed or 4s when playing at 2x speed).
    let prevPosition = this.progress.position
    this.on(Event.Progress, (progress) => {
      if (
        Math.abs(prevPosition - progress.position) >=
        this.playbackRate * 2000
      ) {
        this.mediaSession.update({ state: { position: progress.position } })
      }
      prevPosition = progress.position
    })

    // Deactivate the media session when the video is suspended
    this.on(Event.Suspended, (suspended) => {
      if (suspended) {
        this.mediaSession.deactivate()
      }
    })
  }

  setTrack = (
    track: Track | null,
    options: { initialPosition?: number; playbackRate?: number } = {},
  ): void => {
    if (track !== this.track) {
      this.publishProgress.cancel()
      this.track = track
      this.initialPosition = options.initialPosition || 0
      this.progress = {
        position: this.initialPosition,
        duration: track?.duration || 0,
      }
      if (options.playbackRate) {
        this.setPlaybackRate(options.playbackRate)
      }
      this.setPlaybackState(PlaybackState.Loading)
      this.emitter.emit(Event.Track, this.track)
    }
  }

  setPlaybackState = (playbackState: PlaybackState): void => {
    if (
      playbackState !== this.playbackState &&
      (this.track ||
        playbackState === PlaybackState.Idle ||
        playbackState === PlaybackState.Loading)
    ) {
      this.playbackState = playbackState
      this.emitter.emit(Event.PlaybackState, this.playbackState)
    }
  }

  setPlaybackRate = async (playbackRate: number): Promise<void> => {
    if (playbackRate !== this.playbackRate) {
      this.playbackRate = playbackRate
      this.emitter.emit(Event.PlaybackRate, this.playbackRate)
    }
  }

  getTrack = (): Track | null => this.track

  getInitialPosition = (): number => this.initialPosition

  getProgress = (): Progress => this.progress

  getPlaybackRate = (): number => this.playbackRate

  getPlaybackState = (): PlaybackState => this.playbackState

  getSuspended = (): boolean => this.suspended

  publishProgress = throttle((progress: Progress): void => {
    if (this.track) {
      this.progress = progress
      this.emitter.emit(Event.Progress, progress)
    }
  }, VideoPlayer.PROGRESS_UPDATE_INTERVAL)

  publishSeeked = (position: number): void => {
    if (this.track) {
      this.emitter.emit(Event.Seeked, position)
    }
  }

  publishFinished = (): void => {
    if (this.track) {
      this.emitter.emit(Event.Finished)
    }
  }

  publishPlayIntent = (): void => {
    this.emitter.emit(Event.PlayIntent)
  }

  stop = async (): Promise<void> => {
    if (this.track) {
      await this.emitter.emit(Event.WillStop)
      this.setTrack(null)
      this.setPlaybackState(PlaybackState.Idle)
    }
  }

  suspend = async () => {
    if (!this.suspended) {
      await this.emitter.emit(Event.WillSuspend)
      this.suspended = true
      this.emitter.emit(Event.Suspended, this.suspended)
    }
  }

  resume = (): void => {
    if (this.suspended) {
      this.suspended = false
      this.emitter.emit(Event.Suspended, this.suspended)
    }
  }
}
