import { FirebaseMessagingTokenPostRequest } from "@treefort/api-spec"

import config from "../../config"
import api from "../api"
import { getFirebaseApp } from "../firebase"

type PermissionErrorStatus = "unavailable" | "denied" | "blocked"

type PermissionSuccessStatus = "granted" | "limited"

/**
 * These values originally came from the react-native-permissions package.
 *
 * - unavailable = not supported
 * - denied = not granted but can request
 * - blocked = not granted and can't request
 * - granted = full permissions granted by user
 * - limited = limited permissions granted by system
 */
export type PermissionStatus = PermissionErrorStatus | PermissionSuccessStatus

/**
 * Utilities for managing push notifications. Backed by Firebase Messaging.
 */
export abstract class Notifications {
  /**
   * Get the current permission status for notifications. This will vary based
   * on whether we've asked for permission to send notifications and whether the
   * user has granted, partially granted, or denied our request.
   */
  abstract getPermissionStatus: () => Promise<PermissionStatus>

  /**
   * Fetch the Firebase Messaging token associated with the client. This can
   * also be used to request permission on platforms that require it (iOS and
   * web) via the requestPermission flag. This may return null if we don't have
   * permission to send notifications or if notifications are not supported.
   */
  abstract getToken: (args: { requestPermission: boolean }) => Promise<string>

  /**
   * Resolves to true if notifications are supported.
   */
  abstract isSupported: () => Promise<boolean>

  /**
   * Post the device's notification token to the server. This helps us track the
   * freshness of tokens and ensure that each token's topic subscriptions are
   * up-to-date. This should be called semi-regularly (e.g. once per day) after
   * the auth system is initialized. The `optOut` flag can be set to true or
   * false to disable or enable notifications for the token within our systems
   * (independent of whether the user has granted us permission via the client).
   */
  postToken = async ({
    token,
    optOut,
  }: {
    token: string
    optOut?: boolean
  }): Promise<void> => {
    const senderId = getFirebaseApp()?.options.messagingSenderId
    if (senderId) {
      const data: FirebaseMessagingTokenPostRequest = {
        token,
        clientId: config.CLIENT_ID,
        senderId,
        optOut,
      }
      await api.post("/integrations/firebase-messaging/tokens", data)
    }
  }
}

export class PermissionError extends Error {
  public status: PermissionErrorStatus

  constructor(status: PermissionErrorStatus) {
    super(`[Notifications] Couldn't obtain permission: ${status}`)
    this.name = `[Notifications] Permission Error`
    this.status = status
  }
}
